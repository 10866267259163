const initialState = {
    blog: [],
    products: [],
    productType: [],
    orders: [],
    categories: [],
    cart: []
}

const SET_BLOG = 'SET_BLOG'
const SET_PRODUCTS = 'SET_PRODUCTS'
const SET_PRODUCT_TYPE = 'SET_PRODUCT_TYPE'
const SET_ORDERS = 'SET_ORDERS'
const SET_CATEGORIES = 'SET_CATEGORIES'
const SET_CART = 'SET_CART'

export const setBlog = blog => ({
    type: SET_BLOG, blog
})

export const setProducts = products => ({
    type: SET_PRODUCTS, products
})

export const setProductType = productType => ({
    type: SET_PRODUCT_TYPE, productType
})

export const setOrders = orders => ({
    type: SET_ORDERS, orders
})

export const setCategories = categories => ({
    type: SET_CATEGORIES, categories
})

export const setCart = cart => ({
    type: SET_CART, cart
})

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_BLOG:
            return { ...state, blog: action.blog }
        case SET_PRODUCTS:
            return { ...state, products: action.products }
        case SET_PRODUCT_TYPE:
            return { ...state, productType: action.productType }
        case SET_ORDERS:
            return { ...state, orders: action.orders }
        case SET_CATEGORIES:
            return { ...state, categories: action.categories }
        case SET_CART:
            return { ...state, cart: action.cart }
        default:
            return state
    }
}